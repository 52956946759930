import React from "react";
import { graphql } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import AuditList from "../components/audit-list";
import theme from "../gatsby-theme-material-ui-top-layout/theme";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { Container, Grid, Typography, Button } from "@mui/material";
import { Link } from "gatsby";
import AuditImg from "../components/icon/audit.png";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query AuditsPageQuery {
    audits: allSanityAudit {
      edges {
        node {
          id
          name
          hidden
          logo {
            _key
            _type
            _rawAsset
            _rawHotspot
            _rawCrop
          }
          slug {
            _key
            _type
            current
          }
          website
          commissioned
          status
          description {
            _key
            _type
            style
            list
            _rawChildren
          }
          network
          contracts {
            issues {
              _key
              _type
              description
              severity
              status
            }
            address
            _type
            _key
          }
          auditReport {
            _key
            _type
            _rawAsset
          }
          category
        }
      }
    }
  }
`;

const AuditsPage = (props) => {
  const { data, errors } = props;

  const site = (data || {}).site;
  const auditNodes = (data || {}).audits
    ? mapEdgesToNodes(data.audits).filter(filterOutDocsWithoutSlugs)
    : //.filter(filterOutDocsPublishedInTheFuture)
      [];

  return (
    <Layout>
      <SEO title="Audits" />
      <Container
        maxWidth="md"
        sx={{
          height: "100%",
          minHeight: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid container my={10}>
          <Grid item xs={12} sm={4}>
            <img style={{ width: "100%" }} src={AuditImg} alt="Our Audits" />
          </Grid>
          <Grid
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            item
            xs={12}
            sm={8}
          >
            <Typography variant="h4" color="white" fontWeight="bold">
              Our Audits
            </Typography>
            <Typography
              mt={3}
              variant="subtitle1"
              color={theme.palette.primary.main}
            >
              Our team of independent top auditors and white hat hackers are
              specialists in decentralized finance protocols and are ready to
              assist you in evaluating your project for safety, functionality,
              protection, and utility.
            </Typography>
            <Button
              component={Link}
              to="/contact"
              variant="contained"
              sx={{ mt: 3, borderRadius: 24, fontWeight: "bold" }}
            >
              Request quote
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        sx={{
          background: "white",
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "center",
          flexDirection: "column",
          mt: 5,
          pb: 10,
          minHeight: 600,
        }}
      >
        <Typography mt={5} variant="h4" fontWeight="bold">
          Browse our {auditNodes.filter((a) => !a.hidden).length} audits
        </Typography>
        <Container maxWidth="md">
          {auditNodes && (
            <AuditList
              //title="Our latest audits"
              nodes={auditNodes}
              browseMoreHref="/audits/"
            />
          )}
        </Container>
      </Container>
    </Layout>
  );
};

export default AuditsPage;
