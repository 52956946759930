import React, { useState } from "react";
import { imageUrlFor } from "../lib/image-url";
import {
  Stack,
  Typography,
  Avatar,
  Paper,
  Grid,
  Card,
  Chip,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { Link } from "gatsby";
import { TbClock, TbShieldCheck } from "react-icons/tb";
import theme from "../gatsby-theme-material-ui-top-layout/theme";
function AuditList(props) {
  const [audits, setAudits] = useState(props.nodes);
  const [status, setStatus] = useState("all");

  const handleChange = (e) => {
    setStatus(e.target.value);
    if (e.target.value === "all") {
      setAudits(props.nodes);
    } else {
      setAudits(
        props.nodes.filter(
          (audit) => audit.status.toLowerCase() === e.target.value
        )
      );
    }
  };

  const handleSearchChange = (e) => {
    setAudits(
      props.nodes.filter(
        (audit) =>
          audit.name.toLowerCase().includes(e.target.value) ||
          audit.network.toLowerCase().includes(e.target.value)
      )
    );
  };

  return (
    <Stack>
      <Typography color="white" variant="h4" textAlign="center" p={3}>
        {props.title}
      </Typography>
      <Grid className="nowhite" container justifyContent="space-between" mb={5}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField onChange={handleSearchChange} label="Search" />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Sort by status</InputLabel>
            <Select
              sx={{ textAlign: "left" }}
              value={status}
              label="Sort by status"
              onChange={handleChange}
            >
              <MenuItem value="all">Show all</MenuItem>
              <MenuItem value="in progress">In progress</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {audits.length === 0 && <Typography>No results</Typography>}
      <Grid container spacing={3}>
        {audits &&
          audits
            .filter((n) => !n.hidden)
            .map((node) => {
              return (
                <Grid key={node.id} item xs={12}>
                  <Grid
                    sx={{
                      textDecoration: "none",
                      width: "100%",
                      border: "2px solid " + theme.palette.background.default,
                      borderRadius: 4,
                    }}
                    component={Link}
                    to={`/audit/${node.slug.current}`}
                    container
                    p={3}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid display="flex" alignItems="center" item>
                      <Avatar
                        sx={{ height: 64, width: 64, borderRadius: 0, padding: "8px" }}
                        src={imageUrlFor(node.logo._rawAsset._ref)}
                        alt={node.name}
                      />
                      <Grid ml={3} textAlign="left">
                        <Typography
                          variant="h5"
                          color={theme.palette.background.default}
                          fontWeight="bold"
                        >
                          {node.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          textTransform="uppercase"
                          color={theme.palette.primary.main}
                        >
                          {node.network}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid>
                      {node.status === "Completed" ? (
                        <TbShieldCheck
                          style={{ color: "green", fontSize: "2rem" }}
                        />
                      ) : (
                        <TbClock
                          style={{ color: "orange", fontSize: "2rem" }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {/* <Stack sx={{ textDecoration: "none", flexDirection: "row", alignItems: "center" }} component={Link} to={`/audit/${node.slug.current}`} p={3} spacing={3}>
                  <Stack direction="row" justifyContent="space-between">
                    <Avatar sx={{ height: 64, width: 64 }} src={imageUrlFor(node.logo._rawAsset._ref)} alt={node.name} />

                  </Stack>
                  <Stack alignItems="flex-start" justifyContent="flex-start">

                    <Typography variant="h6" sx={{ lineHeight: "1", pb: 1 }}>{node.name}</Typography>
                    <Typography variant="caption" textTransform="uppercase">{node.network}</Typography>
                  </Stack>
                  {node.status === "Completed" ?
                    <TbShieldCheck style={{ color: "green", fontSize: "2rem" }} /> :
                    <TbClock style={{ color: "orange", fontSize: "2rem" }} />}

                </Stack> */}
                </Grid>
              );
            })}
      </Grid>
    </Stack>
  );
}

AuditList.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default AuditList;
